import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import {
  DemoButton,
  GetStartedButton,
  LearnMoreButton,
  Faq,
  CompareSection,
  TryIrisGPTForFreeBanner
} from "../commonComponents"

import {
  IntegrationHeroSection,
} from "../integrationsUtils"
import {
  IntegrationsMiddleSectionImageLeft,
  IntegrationsMiddleSectionImageRight,
} from "../integrationsMiddleSection"


import { Layout } from "../components/Layout"


export default function SalesforceAIComparison() {
  const SERVICE_NAME = "salesforce"
  const BACKGROUND_SRC = `/img/${SERVICE_NAME}-bg.png`
  const BACKGROUND_WEB_POSITION = "100%"
  const BACKGROUND_MOBILE_POSITION = "35% 50px"
  return (
    <Layout
      title="Why IrisAgent is better than Salesforce Einstein AI?"
      keywords="IrisAgent vs Salesforce, IrisAgent compare with Salesforce AI"
      description="IrisAgent is a comprehensive, feature-rich AI support automation platform designed to seamlessly integrate with your CRM system. Discover below why IrisAgent offers superior support automation compared to native Salesforce Automation."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/salesforce-ai-and-irisagent/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <IntegrationHeroSection
        serviceName={SERVICE_NAME}
        titleIntegration={
          <>
            <span className="title-fucsia-color">
              IrisAgent vs <br/>Salesforce AI:
            </span> Why <br/>it's a nobrainer?
          </>
        }
        descriptionIntegration={
          <>
            <span>
              IrisAgent is a comprehensive, feature-rich AI support
              automation platform designed to seamlessly integrate
              with your CRM system. Discover below why IrisAgent
              offers superior support automation compared to native
               Salesforce Automation.
            </span>
          </>
        }
        sectionBackgroundSrc={BACKGROUND_SRC}
        sectionBackgroundWebPosition={BACKGROUND_WEB_POSITION}
        sectionBackgroundMobilePosition={BACKGROUND_MOBILE_POSITION}
      />
      {/* <!-- End Hero --> */}

      {/* <!-- ======= Counts Section ======= --> */}
      <CompareSection theme="dark" service="Salesforce"/>
      {/* <!-- End Counts Section --> */}
      <main id="main">



        <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "4%", margin:"10%" }}>
            <h2><span style={{ color: "var(--text-highlight-color)", fontWeight: 600 }}>Check out how we compare</span></h2>
            <br/>
            <table>
                <tr>
                    <th>Features</th>
                    <th>IrisAgent</th>
                    <th>Salesforce</th>
                </tr>
                <tr>
                    <td>Generative AI Chatbot</td>
                    <td>✔ LLM based<br/>
                    ✔ Configurable LLM federation with OpenAI, Anthropic, Azure, and more<br/>
                    ✔ Integrates to multiple KBs and sources<br/>
                    ✔ 95%+ accuracy, zero hallucinations<br/><br/></td>
                    <td>⤬ Rule based<br/>
                        ⤬ Restricted to Salesforce Knowledge
                    </td>
                </tr>
                <tr>
                    <td>Email auto-responder</td>
                    <td>✔ AI-Powered</td>
                    <td>⤬ Rule based</td>
                </tr>
                <tr>
                    <td>Intelligent AI Search</td>
                    <td>✔ AI-Powered and Federated across multiple knowledge sources</td>
                    <td>⤬ Rule based and only searches Salesforce data</td>
                </tr>
                <tr>
                    <td>AI Agent Assist</td>
                    <td>✔ LLM based answers from articles, historical tickets, and bugs<br/>
                        ✔ AI-powered Macro auto-selection</td>
                    <td>⤬ Scripted, searches only Salesforce data<br/>
                        ⤬ Manual macro selection</td>
                </tr>
                <tr>
                    <td>Automated tagging, routing, and triaging</td>
                    <td>✔ AI powered, trained on historical tickets</td>
                    <td>⤬ Keyword based tagging and routing
                      </td>
                </tr>
                <tr>
                    <td>Sentiment and escalation analysis</td>
                    <td>✔ AI powered, granular, measured per ticket </td>
                    <td>⤬ Keyword and rule-based
                      </td>
                </tr>
            </table>
            <br/>
            </div>
          </div>
        </section>
      <TryIrisGPTForFreeBanner />
      {/*  End Cta Section  */}
      {/* <!-- ======= Middle Section ======= --> */}
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/salesforceMiddle1.webp"
        imageTextMiniature="/img/salesforceMiniature1.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>
            Resolve common cases
            <br /> automatically with AI
          </>
        }
        descriptionTextContent={
          <>
            Access appropriate resolutions based on similar cases, knowledge
            base articles, and AI models from within Salesforce. Automated machine learning within the Salesforce platform ensures that enterprises can fully utilize machine learning capabilities that are production-ready.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/irisgpt.svg"
        imageTextMiniature="/img/salesforceMiniature2.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>Deflect 40% of cases and chats with Salesforce AI and IrisGPT Chatbot integration</>
        }
        descriptionTextContent={
          <>
            Trained on the world's largest large language models, IrisGPT's generative AI generates multilingual, trustworthy, and highly accurate answers integrated in your Salesforce chatbot
          </>
        }
      />
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/salesforceMiddle3.webp"
        imageTextMiniature="/img/salesforceMiniature3.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>
            Automate ticket tagging, routing and
            <br /> prioritizing within Salesforce
          </>
        }
        descriptionTextContent={
          <>
            IrisAgent discovers tags customized to your domain and automatically
            tags new tickets. Utilizing CRM data, it enhances ticket tagging and routing by providing personalized, data-driven automation. Get rid of manual and inaccurate ticket tagging.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/salesforceMiddle2.webp"
        imageTextMiniature="/img/salesforceMiniature2.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>Add product context from DevOps tools to Salesforce cases</>
        }
        descriptionTextContent={
          <>
            Instant problem discovery using internal operational and product signals without the need for separate data preparation. Utilizing customer data helps in understanding the business priority and history of the customer and empathize better. Link tickets to known product bugs in Jira.
          </>
        }
      />

      {/* <!-- End Middle Section --> */}


        {/* <!-- ======= Contact Services Section ======= --> */}
        {/* <ContactSection /> */}
        {/* <!-- End Contact Section --> */}
      </main>
      {/*  End #main  */}
    </Layout>
  )
}